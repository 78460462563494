import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Head from './Head'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paginator from 'components/Paginator'

TableList.propTypes = {
  title: PropTypes.string.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      numeric: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      display: PropTypes.array,
    }).isRequired
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  sort: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageSizes: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleSort: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  totalPage: PropTypes.number.isRequired,
  selectable: PropTypes.bool,
  emptyTableLabel: PropTypes.string,
}

TableList.defaultProps = {
  pageSize: 10,
  currentPage: 1,
  pageSizes: [5, 10, 50],
  sort: 'desc',
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '0',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  customPagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F5FDFD',
    },
  },
}))

function TableList(props) {
  const classes = useStyles()
  const {
    headCells,
    data,
    currentPage,
    handlePageChange,
    isLoading,
    sort,
    handleSort,
    totalPage,
    selectable = true,
    emptyTableLabel = 'データがありません',
  } = props
  const sortBy = props.sortBy || 'id'
  const dataList = useMemo(() => data, [data])
  // Selected rows
  const [selected, setSelected] = useState([])
  // Minus 1 for the current page because pagination component is zero based
  const handleRequestSort = (event, property) => {
    setSelected([])
    const isAsc = sortBy === property && sort === 'asc'
    handleSort(isAsc ? 'desc' : 'asc', property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let selection = dataList.map((n) => n.id)
      setSelected(selection)
      return
    }

    setSelected([])
  }
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }
  const isSelected = (id) => {
    return selected.indexOf(id) !== -1
  }

  const onPageChange = (event, value) => {
    handlePageChange(value)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="table list"
          >
            <Head
              classes={classes}
              numSelected={selected.length}
              order={sort}
              orderBy={sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={dataList.length}
              headCells={headCells}
              isLoading={isLoading}
            />
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={headCells.length} align={'center'}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {dataList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align={'center'}>
                      {emptyTableLabel}
                    </TableCell>
                  </TableRow>
                ) : (
                  dataList.map((row, index) => {
                    const isItemSelected = selectable ? isSelected(row.id) : null
                    const labelId = `table-checkbox-${index}`
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        className={classes.row}
                      >
                        {createCells(headCells, row, isItemSelected, labelId)}
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Paginator
          pageCount={totalPage}
          currentPage={currentPage}
          itemCount={dataList.length}
          itemsPerPage={10}
          onPageChange={isLoading ? () => {} : onPageChange}
        />
      </Paper>
    </div>
  )
}

function createCells(headCells, rowData) {
  let cells = []

  // Iterate thru the headCells, this will be the basis for the display of data
  for (let i = 0; i < headCells.length; i++) {
    const headCell = headCells[i]

    // Process only shown columns
    if (headCell.hide !== true) {
      // Default display value is base from the column ID
      let value = rowData[headCell.id]

      // Value is an object, we need to get the specific value for display
      if (headCell.display !== undefined && typeof value === 'object') {
        // Iterate to all given indeces and value to be the value of the last index
        for (let ii = 0; ii < headCell.display.length; ii++) {
          value = value[headCell.display[ii]]
        }
      }

      const IconComponents = headCell.component
      cells.push(
        <TableCell
          key={`${headCell.id}-${rowData.id}`}
          style={{
            textAlign: headCell.align ? headCell.align : 'left',
            maxWidth: '100px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {headCell.component ? <IconComponents id={rowData.id} data={rowData} /> : value}
        </TableCell>
      )
    }
  }

  return cells
}

export default TableList
